$(document).foundation();

$(document).ready(function() {
    $('[data-validate-form]').each(function(i, val) {
        $(val).validate({
            errorPlacement: function(error, element) { }
        });
    });

    $('[data-scroll-to]').each(function(i, val) {
        $(val).on('click', function(ev) {
            ev.preventDefault();
            $('html, body').animate({
                scrollTop: $($(val).attr('data-scroll-to')).offset().top
            }, 1000);
        });
    });
});

$('#header button.hamburger').on('click', function() {
    $('body').toggleClass('nav-open');
    $(this).toggleClass('is-active');
});

$(document).on('touchmove', function() {
    $('body').addClass('touch-move');
});

$(document).on('touchend', function() {
    $('body').removeClass('touch-move');
});

$(document).on('touchmove', 'body.nav-open', function(e){
     e.preventDefault();
});

var mapAddress, map, geocoder, marker;
var styles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#feeaad"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#b5d2f6"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

function geocode(address, cb) {
    if(geocoder) {
        geocoder.geocode({
            'address': address,
            'componentRestrictions': {
                country: 'BE'
            }
        }, function(results, status) {
            if(status == google.maps.GeocoderStatus.OK) {
                cb({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                });
            }
            else {
                cb(false);
            }
        });
    }
    else {
        cb(false);
    }
}

function initMap() {
    if($('#gmap').length) {
        geocoder = new google.maps.Geocoder();
        map = new google.maps.Map(document.getElementById('gmap'), {
            mapTypeControlOptions: {
                mapTypeIds: ['mystyle', google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN]
            },
            center: {
                lat: 51,
                lng: 3.6
            },
            zoom: 13,
            disableDefaultUI: true,
            streetViewControl: false,
            zoomControl: true,
            scrollwheel: false,
            mapTypeId: 'mystyle'
        });

        map.mapTypes.set('mystyle', new google.maps.StyledMapType(styles, { name: 'My Style' }));

        geocode(mapAddress, function(latLng) {
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(latLng.lat, latLng.lng),
                map: map,
        		icon: '/img/marker.png'
            });

            map.setCenter(latLng);
        })
    }
}

if($('.section.section__product').length) {
    var galleryLarge = new Swiper('.swiper-container.large', {
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    });

    var galleryThumbs = new Swiper('.swiper-container.thumbs', {
        spaceBetween: 20,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
    });

    galleryLarge.controller.control = galleryThumbs;
    galleryThumbs.controller.control = galleryLarge;
}

$(document).on('click', '.summary button.add', function(ev) {
    ev.preventDefault();
    var value = parseInt($(this).prev().val());
    $(this).prev().val(parseInt(value)+1);
});

$(document).on('click', '.summary button.subtract', function(ev) {
    ev.preventDefault();
    var value = parseInt($(this).next().val());
    if(value > 1)
        $(this).next().val(parseInt(value)-1);
});

if($('[data-isotope-grid]').length) {
    var $grid = $('[data-isotope-grid]').isotope({
        itemSelector: '.block__product',
        percentPosition: true,
        masonry: {
            columnWidth: '.block__product'
        }
    });

    $grid.imagesLoaded().progress(function() {
        $grid.isotope('layout');
    });

    var filter = [];
    $('[data-filter]').on('change', function() {
        filter = [];

        $('[data-filter]').each(function() {
            if($(this).is(':checked')) {
                filter.push($(this).attr('data-filter'));
            }
        });

        var filterValue = filter.length ? filter.join(', ') : '*';

        $grid.isotope({filter:filterValue});
    });
}
